/* 
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
}

.homepage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

} */

/* Header styles */
/* .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background-color:#4ac8eb;
  height: 50px;
  color: white;
}


.nav a {
  margin: 0 10px;
  color: white;
  text-decoration: none;
}

.nav a:hover {
  text-decoration: underline;
} */

/* Content styles */
/* .content {
  text-align: center;
  background-size: cover;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.style1{
  background: linear-gradient(45deg, #60eaf7, #07175e);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.content h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.content p {
  font-size: 18px;
  line-height: 1.6;
}


.image-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  align-items: flex-start;
}

.image-item {
  text-align: center;
  margin: 10px;
  width:150px;
} */

.image-item img {
  width: 100px; 
  height: auto;
  display: block;
  margin: 0 auto;
}

.image-item p {
  margin-top: 5px;
  min-height: 40px;
  font-size: 14px; 
}


.justify-text {
  text-align: justify;
}


/* Responsive styles */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav {
    flex-direction: column;
    gap: 10px;
  }

  /* .logo-img {
    height: 40px;
    width: 40px;
  }
} */
}
@media (max-width: 480px) {
  .nav a {
    font-size: 14px;
  }

  .content h1 {
    font-size: 28px;
  }

  .content p {
    font-size: 16px;
  }

  .image-item img {
    width: 80px;
  }
}
