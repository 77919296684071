.custom-popconfirm .ant-popconfirm-content {
  font-size: 16px;
  text-align: center;
  max-width: 300px; /* Adjust the max-width as needed */
}

.custom-popconfirm .ant-popconfirm {
  max-width: 300px; /* Adjust the max-width as needed */
}
  

.action-button {
  width: 100px; /* Adjust this width as needed */
  text-align: center;
}

.action-button1 {
  width: 70px; /* Adjust this width as needed */
  /* text-align: center; */
}