.signup-container {
    width: 350px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 50px;
}

.signup-container h2 {
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.error,
.success {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
}

.error {
    background-color: #ffcccc;
    color: #cc0000;
}

.success {
    background-color: #ccffcc;
    color: #006600;
}

.input-box {
    margin-bottom: 15px;
    margin-right: 50px;
    
}

.input-box input::placeholder{
    color:#040606;
}

.input-box label {
    display: block;
    margin-bottom: 5px;
    color: #333;
}

.input-box input[type="text"],
.input-box input[type="email"],
.input-box input[type="password"] {
    width: 70%;
    padding: 8px;
    margin-left: 50px;
    border: 1px solid #2b2b2e;
    border-radius: 3px;
}

button {
    /* width: 80%; */
    padding: 10px;
    border: none;
    border-radius: 3px;
    background-color: #0a2271;
    background: #86d6ec;
    cursor: pointer;
}

button:hover {
    background-color: #555;
}

p {
    margin-top: 20px;
    text-align: center;
    color: #333;
    margin-right: 10px;
}

p a {
    color: #333;
    font-weight: bold;
}

p a:hover {
    text-decoration: underline;
}
