.fundhouse-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .upload-section,
  .remarks-section {
    margin: 20px 0;
  }
  
  .upload-section label,
  .remarks-section label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .upload-button {
    margin-top: 20px;
    width: 100%;
  }
  