.login-container{
    width:420px;
    background: rgba(45, 125, 141, 0.716);
    border: 2px solid rgba(255,255,255, .2);
    backdrop-filter: blur(30px);
    color: #fff;
    padding:20px 30px;
    margin-left: 450px; 
    
    margin-top: 75px;
    box-shadow: 2px 6px 7px rgba(5, 5, 5, 1.2); /* Add box shadow */
    
}

body{
   background:#fff;
   background-size: cover;
   background-position: center;
    
}

.login-container h2{
    font-size:36px;
    text-align:center;
    margin-left: 10px;

}

.login-container .input1-box{
    position: relative;
    width:82%;
    height:20px;
    margin:30px 0;
    margin-left: 10px;
    border-color: #fff;
}

.input1-box input{
    width:100%;
    height:100%;
    background:transparent;
    border:none;
    outline:none;
    border: 2px solid rgba(236, 248, 249, 0.955);
    border-radius:40px;
    font-size:16px;
    color:#fff;
    padding:20px 45px 20px 20px;
}

.input1-box input::placeholder{
    color:#fff;
}

.input1-box .icon{
    position:absolute;
    right:5px;
    left:350px;
    top:140%;
    transform:translateY(-50%);
    font-size :16px;
}

.login-container .remember-forgot{
    display:flex;
    justify-content:space-between;
    font-size:14.5px;
    margin:-15px 0 15px;
}

.remember-forgot label input{
    accent-color:#fff;
    margin-right: 10px;
}

.remember-forgot a{
    margin-right: 20px;
    accent-color:#0b1e98;
    text-decoration:none;
    color:#2f1180;
    font-weight:630;
}

.remember-forgot a:hover{
    text-decoration:underline;
}

.remember-forgot .register-link{
    font-size:14. 5px;
    margin-left: 20px;
    margin:20px 0 15px;

}


.register-link p a{
    accent-color:#2f1180;
    font-weight:600;
    text-decoration:none;
    color: #2f1180;
   
}

.register-link p a :hover{
    text-decoration:underline;
}

