/* styles.css */
.summary-box-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .summary-box-title {
    font-size: 16px;
    font-weight: bold;
  }
  
  .summary-box-number {
    font-size: 24px;
    margin-top: 10px;
  }
  
  .total-clients-box {
    background-color: #ff4d4f; /* Red */
    color: white;
  }
  
  .total-goals-box {
    background-color: #ffd666; /* Yellow */
    color: white;
  }
  
  .total-sips-box {
    background-color: #b37feb; /* Brown */
    color: white;
  }
  
  .total-lumpsum-box {
    background-color: #52c41a; /* Green */
    color: white;
  }
  