
.display {
    display: flex;
    height: 100vh;
  }
  
.header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    background-color: #f0f2f5;
    border-bottom: 1px solid #d9d9d9;
    background:linear-gradient(85deg, #FFA630, #00cffc);
  }
  
  
  /* Style for the menu */
  .labels {
   
    width: 200px; /* Adjust width as needed */
    background-color: #0bcbd8; /* Set background color */
  }
  
  /* Style for the content */
  .content1 {
    
    margin-left: 200px; /* Adjust margin as needed */
    padding: 20px;
    flex: 1;
  }
  
  /* Style for menu items */
  .menu-item {
    padding: 1px;
    cursor: pointer;
  }
  
  .labels .ant-menu-item {
    margin-top: 0px;
    color: hsla(0, 4%, 5%, 0.973); /* Change the color as needed */
  }
  
  /* Style for active menu item */
  
  
  /* Style for nested menu items */
  .sub-menu {
    margin-left: 10px; /* Indent nested menu items */
  }
  
  /* Style for the active menu item */
.labels .ant-menu-item-selected {
    color: #130e0e; /* Change the color as needed */
  }
  
  /* Style for the nested menu items */
  .labels .ant-menu-submenu-title {
    color: #0f0b0b;
    /* Change the color as needed */
  }
  
  /* Style for the Master Data label */
  .labels .ant-menu-item[data-key='/clientDashBoard/masterdata'] {
    color: rgb(19, 16, 16); 
    /* Color for Master Data label */
  }
  
  /* Style for the children of Master Data label */
  .labels .ant-menu-submenu-popup .ant-menu-item {
    color: black; /* Color for Master Data children */
  }

  
.menu-label {
    padding: 10px 20px; /* Adjust the padding values as needed */
  }
  
  .menu-item {
    padding: 10px 10px; 
    /* Same padding for consistency */
  }
  .menu-item :hover{
    color: aqua;
  }
  
  .menu-item-sub {
    padding: 1px 1px; /* Padding for submenu items */
  }
  
  .menu-item-sub-parent {
    padding: 10px 10px; /* Padding for submenu parent item */
  }
  
  /* Example of how to target specific items, like Master Data children */
  .menu-item-master-data {
   margin-top: 5px; /* Set background color to white for visibility */
  }
  
  .user-button{
  width:'100px';
  align-items: center;
  border-radius:10px;
  height: 50px;
  background:'#00dffe';
  padding: 0;
  margin-right: 20px;
  
  }
 