/* Profile.css */
/* .profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
  }
  
  .profile-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
.profile-container {
    padding: 20px;
  }
  
  .profile-photo-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  
  
  .upload-button {
    margin-bottom: 20px;
  }
  
  .upload-input {
    display: none;
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
  }
  
  .profile-form .ant-form-item {
    display: flex;
    align-items: center;
  }
  
  .profile-form .ant-form-item-label {
    width: 120px; 
    text-align: left;
  }
  
  .profile-form .ant-form-item-control {
    flex: 1; 
  }
  
  .profile-form .ant-input {
    width: 100%;
  }
  
  .profile-form-buttons {
    display: flex;
    justify-content: flex-end;
  }
  
  .profile-form-buttons .ant-btn {
    margin-left: 10px;
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }


.profile-form .ant-form-item {
    margin-bottom: 8px; 
  } */


  .profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .profile-content {
    display: flex;
    align-items: flex-start;
  }
  
  .profile-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 50px;
  }
  
  .profile-right {
    display: flex;
    flex-direction: column;
  }
  